<template>
  <div class="container app-content pt-4">
    <Error v-if="error" :error="error" />
    <Loading v-else-if="isLoading" />
    <template v-else>
      <!-- Page Header -->
      <PageHeader :title="`${formatAmountStat(donation.total)} by ${displayAs}`" />

      <DonationNavigation
        :donation="donation"
        :is-deleted="isDeleted(donation)"
        @deleteDonation="deleteDonation"
        @restoreDonation="restoreDonation"
      />

      <div class="row mt-4">
        <div class="col-md-6">
          <div class="card">
            <div class="card-body">
              <h5>Donor Info</h5>
              <dl class="row">
                <dt class="col-sm-3">Name</dt>
                <dd class="col-sm-9">
                  <router-link
                    v-if="can('view', 'donors')"
                    :to="{ name: 'donor', params: { id: donation.donor_id } }"
                  >
                    {{ displayAs }}
                  </router-link>
                  <template v-else>
                    {{ displayAs }}
                  </template>
                </dd>

                <template v-if="can('view', 'donors')">
                  <dt class="col-sm-3">Address</dt>
                  <dd class="col-sm-9">
                    {{ donor.street }}<br />
                    {{ donor.city }}, {{ donor.state }} {{ donor.zip }}
                  </dd>
                  <dt class="col-sm-3">Email</dt>
                  <dd class="col-sm-9">{{ donor.email }}</dd>
                </template>
              </dl>
            </div>
          </div>
        </div>

        <div class="col-md-6">
          <div class="card">
            <div class="card-body">
              <h5>Donation Info</h5>
              <dl class="row">
                <dt class="col-sm-4">Amount</dt>
                <dd class="col-sm-8">{{ formatAmountStat(donation.total) }}</dd>

                <dt class="col-sm-4">Payment Date</dt>
                <dd class="col-sm-8">{{ shortDateTime(donation.payment_made_at) }}</dd>

                <dt class="col-sm-4">Type</dt>
                <dd class="col-sm-8">{{ type }}</dd>

                <dt class="col-sm-4">Recurring</dt>
                <dd class="col-sm-8">{{ isRecurring ? 'Yes' : 'No' }}</dd>

                <dt class="col-sm-4">Channel</dt>
                <dd class="col-sm-8">{{ channel }}</dd>

                <dt class="col-sm-4">Campaign</dt>
                <dd class="col-sm-8">
                  <template v-if="!this.donation.campaign_id"> -- </template>
                  <template v-else>
                    <Error v-if="errorCampaign" :error="errorCampaign" />
                    <Loading
                      v-else-if="isLoadingCampaign"
                      :size="`spinner-border-sm`"
                      color="dark"
                    />
                    <template v-else>
                      <router-link
                        :to="{
                          name: 'campaign',
                          params: { id: this.campaign.campaign_id },
                        }"
                      >
                        {{ campaign.title }}
                      </router-link>
                    </template>
                  </template>
                </dd>

                <dt class="col-sm-4">Promotion</dt>
                <dd class="col-sm-8">
                  <template v-if="!this.donation.promotion_id"> -- </template>
                  <template v-else>
                    <Error v-if="errorPromotion" :error="errorPromotion" />
                    <Loading
                      v-else-if="isLoadingPromotion"
                      :size="`spinner-border-sm`"
                      color="dark"
                    />
                    <template v-else>
                      <router-link
                        :to="{
                          name: 'promotion',
                          params: { id: this.promotion.promotion_id },
                        }"
                      >
                        {{ promotion.title }}
                      </router-link>
                    </template>
                  </template>
                </dd>

                <dt class="col-sm-4">Form ID</dt>
                <dd class="col-sm-8">{{ donation.form_id }}</dd>

                <template v-if="isDeleted(donation)">
                  <dt class="col-sm-4">Deleted At</dt>
                  <dd class="col-sm-8">
                    {{ donation.deleted_at }}
                  </dd>
                </template>
              </dl>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col">
          <h5>Comments</h5>
          <div v-html="comments"></div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import * as _ from 'lodash';
// import dayjs from 'dayjs';
import hasStatistics from 'mixins/hasStatistics';
import hasTimeDate from 'mixins/hasTimeDate';
import helpers from 'mixins/helpers';
import DonationNavigation from 'components/donations/DonationNavigation';
import Error from 'components/Error';
import Loading from 'components/Loading';
import PageHeader from 'components/PageHeader';

export default {
  name: 'DonationsShow',
  mixins: [hasStatistics, hasTimeDate, helpers],
  components: { DonationNavigation, Error, Loading, PageHeader },
  props: {
    id: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      error: null,
      errorCampaign: null,
      errorPromotion: null,
      isLoading: false,
      isLoadingCampaign: false,
      isLoadingPromotion: false,
    };
  },
  computed: {
    campaign() {
      return this.$store.getters['campaigns/getCurrentCampaign'];
    },
    channel() {
      return _.upperFirst(this.donation.channel);
    },
    comments() {
      return this.donation.comments ? this.donation.comments.replace('&amp;', '&') : 'None';
    },
    displayAs() {
      if (this.donation.anon) {
        return 'Friend';
      }

      if (this.can('view', 'donors')) {
        if (this.donor.display_as) {
          return this.donor.display_as;
        }

        return [this.donor.first, this.donor.last].join(' ');
      }

      return this.donor.first;
    },
    donation() {
      return this.$store.getters['donations/getCurrentDonation'];
    },
    donor() {
      return this.donation.donor;
    },
    // isDeleted() {
    //   if (this.hasProperty(this.donation, 'deleted_at')) {
    //     return dayjs(this.donation.deleted_at).isValid();
    //   }

    //   return false;
    // },
    isRecurring() {
      return this.donation.recurring;
    },
    promotion() {
      return this.$store.getters['promotions/getCurrentPromotion'];
    },
    type() {
      return _.upperFirst(this.donation.type);
    },
  },
  created() {
    this.fetchData();
  },
  methods: {
    deleteDonation() {
      this.error = null;
      this.isLoading = true;

      const payload = {
        id: this.id,
      };

      // console.log('DonationsShow methods deleteDonation() before :payload', payload);

      this.$store
        .dispatch('donations/destroy', payload)
        .catch((error) => {
          console.error('DonationsShow methods deleteDonation() catch', error);
          this.error = this.$errorProcessor(error);
        })
        .then(() => {
          // console.log('DonationsShow methods deleteDonation() then');
          this.isLoading = false;

          this.fetchData();
        });
    },
    restoreDonation() {
      this.error = null;
      this.isLoading = true;

      const payload = {
        id: this.id,
      };

      // console.log('DonationsShow methods restoreDonation() before :payload', payload);

      this.$store
        .dispatch('donations/restore', payload)
        .catch((error) => {
          console.error('DonationsShow methods restoreDonation() catch', error);
          this.error = this.$errorProcessor(error);
        })
        .then(() => {
          // console.log('DonationsShow methods restoreDonation() then');
          this.isLoading = false;

          this.fetchData();
        });
    },
    fetchData() {
      // #TODO lock the page while loading
      this.error = null;
      this.isLoading = true;

      const payload = {
        id: this.id,
        params: {
          with: 'donor',
        },
      };

      // console.log('DonationsShow methods fetchData() before :payload', payload);

      this.$store
        .dispatch('donations/get', payload)
        .catch((error) => {
          this.error = this.$errorProcessor(error);
        })
        .then(() => {
          // console.log('DonationsShow methods fetchData() then');
          this.isLoading = false;

          if (this.donation.campaign_id) {
            this.fetchCampaign();
          }

          if (this.donation.promotion_id) {
            this.fetchPromotion();
          }
        });
    },
    fetchCampaign() {
      this.errorCampaign = null;
      this.isLoadingCampaign = true;

      this.$store
        .dispatch('campaigns/get', { id: this.donation.campaign_id })
        .then(() => {
          // console.log('DonationsShow methods fetchCampaign() then');
          this.isLoadingCampaign = false;
        })
        .catch((error) => {
          this.errorCampaign = this.$errorProcessor(error);
        });
    },
    fetchPromotion() {
      this.errorPromotion = null;
      this.isLoadingPromotion = true;

      this.$store
        .dispatch('promotions/get', { id: this.donation.promotion_id })
        .then(() => {
          // console.log('DonationsShow methods fetchPromotion() then');
          this.isLoadingPromotion = false;
        })
        .catch((error) => {
          this.errorPromotion = this.$errorProcessor(error);
        });
    },
  },
};
</script>

<style scoped lang="scss"></style>
